import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import SocialProfile from "../../components/SocialProfile/SocialProfile"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from "react-icons/io"
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style"

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: "https://www.facebook.com/exploretheweb/",
    tooltip: "Facebook",
  },
  {
    icon: <IoLogoInstagram />,
    url: "https://www.instagram.com/anuragdraws/",
    tooltip: "Instagram",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/ak_8085",
    tooltip: "Twitter",
  },

]

interface AboutProps { }

const About: React.FunctionComponent<AboutProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Me</h2>

        {/*
            <p>
              StoryHub is a beautiful Gatsby Blog theme designed to showcase your
              work in style. Perfect for designers, artists, photographers and
              developers to use for their portfolio website.
              </p> */}
      </AboutPageTitle>



      <AboutDetails>
        <AboutImage>
          <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
        </AboutImage>

        <h2>👋 Hey there, I am Anurag</h2>
        <p>Indie maker building products on the internet 🤓</p>
        <p>Currently building: <a target="_blank" href="https://productlogz.com">ProductLogz</a></p>
        <h3>Little Background</h3>

        <p> I have an insatiable interest for online technology and digital publishing.
          During my early days with the internet I was quite obsessed with the latter.
          The same obsession taught me how things work in the online world and how can I can be a part of it too.
          It was around the same time that I picked up blogging.
          I had this nagging desire to create things in life and writing fulfilled my wishes to some extent.
          Later on I also worked for few startups as a writer which was a moral booster.
        </p>
        <p>Fast forward to present: I write both for humans & machines. But the desire to create things on internet still exits, which ultimately drives me forward.
        </p>

        <h3>Journey so far</h3>

        <p>I've been working with startups for over 11 years, often as the first employee.
          I've taken on roles as both a backend and frontend developer.
          These early-stage startups taught me everything I know about technology and how software works.</p>

        <p>Besides software and startups, I’ve always had a keen interest in creating random sketches and illustrations, a skill I started learning when I was about 5 years old.
          Through this blog, I aim to blend my drawing and technical knowledge to offer valuable insights to my readers.</p>

        <p>
          Also I would like to thank <a href="https://x.com/inkdrop_app" target="_blank">Takuya Matsuyama</a> , <a href="https://x.com/levelsio" target="_blank">Pieter Levels</a> , <a href="https://x.com/csallen" target="_blank">Courtland Allen</a>, and <a href="https://swizec.com" target="_blank">Swizec Teller</a>. These guys are amazing! Their work totally changed how I think about tech and my career.
        </p>
        <br />
        <div>
          <sub><sup>Home Page Photo by <a href="https://unsplash.com/@szolkin">Sergey Zolkin</a> on <a href="https://unsplash.com">Unsplash</a> </sup></sub>
        </div>

        {/* <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles> */}
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
